import React from 'react';
import {
  useMsal
} from '@azure/msal-react';
import styles from './UnauthenticatedView.module.css';
import Image from 'next/image';

function SignInButton() {
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
  const { instance } = useMsal();

  return <button onClick={() => instance.loginRedirect()}>Sign In</button>;
}

const UnauthenticatedView = () => {
  return (
    <div className={`${styles.login_container}`}>
      <div className={styles.login_box}>
        <Image src="/images/microsoft_logo.png" alt="Microsoft" width="108px" height="24px" />
        <p>To access iStory, click Sign In below.</p>
        <SignInButton />
      </div>
    </div>
  )
}

export default UnauthenticatedView;