import Link from 'next/link';
import $ from 'jquery';
import { useEffect } from 'react';

const Header = ({page}) => {
  const pageName = page;

useEffect(() => {
  
$('.mobile-menu-button').click(function() {
  $('.mobile-button a').toggleClass('active');
  $('.main-menu').toggleClass('open');
  $('.primary-header').toggleClass('open');
	});
  
})

  return (
    <header className={`text-white-600 body-font absolute w-full z-10 primary-header ${pageName}-header`}>
      <style jsx>{`
        .story-header {
          background: rgb(74,140,176);
          background: -moz-linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
          background: -webkit-linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
          background: linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
        }
      `}</style>
      <div className="container mx-auto flex flex-wrap p-6 md:flex-row items-center">
      <Link href="/"><a className="flex title-font logo text-white font-medium items-center mb-4 md:mb-0 lato">
          <span className="text-xl font-bold">i</span><span className="text-xl font-lightish">Story</span>
        </a></Link>
        <div className="mobile-menu-button">
          <div className="mobile-menu-button-inner">
            <div className="mobile-button"><a href="javascript:void(0)" className=""><span className="icon"></span></a>
            </div>
          </div>
        </div>
        <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center main-menu text-base justify-center">
        <Link href="/stories"><a className="mlr-6 font-bold hover:text-blue-900">Stories</a></Link>         
        <Link href="/timeline"><a className="mlr-6 font-bold hover:text-blue-900">Timeline</a></Link> 
        <Link href="/protiviti-pursuit"><a className="mlr-6 font-bold hover:text-blue-900 mobile-only">Pursuit</a></Link> 
        <Link href="/contribute"><a className="mlr-6 font-bold hover:text-blue-900">Contribute</a></Link> 
        </nav>
        <div className="account-btn">
          <Link href="/stories">
            <a className="inline-flex items-center border-0 py-1 px-3 focus:outline-none mt-4 md:mt-0 sm:pt-0 sm:mt-8">
            <svg width="20.454" height="21.397" viewBox="0 0 20.454 21.397">
              <g id="Group_31" data-name="Group 31" transform="translate(0 0)">
                <g id="Mask_Group_30" data-name="Mask Group 30" transform="translate(0 0)" clipPath="url(#clip-path)">
                  <path id="Path_81" data-name="Path 81" d="M19.229,19.547,13.9,14.223a8.223,8.223,0,1,0-.672.573l5.373,5.373ZM.88,8.242A7.362,7.362,0,1,1,8.242,15.6,7.37,7.37,0,0,1,.88,8.242" transform="translate(0 0)" fill="#fff"/>
                  <path id="Path_82" data-name="Path 82" d="M109.594,114.316a.634.634,0,1,0,.634.634.634.634,0,0,0-.634-.634" transform="translate(-89.774 -94.187)" fill="#fff"/>
                </g>
              </g>
            </svg>
            </a>
          </Link>
          <Link href="/my-library">
            <a className="inline-flex items-center border-0 py-1 px-3 focus:outline-none mt-4 md:mt-0 sm:pt-0 sm:mt-8">
              <svg width="20.203" height="21.049" viewBox="0 0 20.203 21.049">
                <g id="Group_32" data-name="Group 32" clipPath="url(#clip-path)">
                  <path id="Path_83" data-name="Path 83" d="M13.474,11.269l-.652.5.753.326A9.025,9.025,0,0,1,18.9,18.964h.976a9.988,9.988,0,0,0-5.234-7.427A6.648,6.648,0,0,0,16.7,6.71a6.71,6.71,0,1,0-13.421,0,6.648,6.648,0,0,0,2.056,4.826A9.987,9.987,0,0,0,0,20.376v.482H18.117v-.965H.978a9.024,9.024,0,0,1,5.424-7.8l.753-.326-.652-.5a5.745,5.745,0,1,1,6.971,0" transform="translate(0 0)" fill="#fff"/>
                  <path id="Path_84" data-name="Path 84" d="M98.641,103.027a.7.7,0,1,0-.983,0,.7.7,0,0,0,.983,0" transform="translate(-78.642 -82.182)" fill="#fff"/>
                </g>
              </svg>
            </a>
            </Link>
          </div>
      </div>
    </header>
  )
}

export default Header;